import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { RoutesWrapper, RoutesGuard, RoleGuard } from './RoutesGuard';
import NotFound from '../components/pages/NotFound';
import Login from '../components/pages/Login';
import Layout from '../components/pages/Layout';

import LoginToDashboard from './LoginToDashboard';
import ToLoginPageRedirection from './ToLoginPageRedirection';

import DataBaseProcessingRoutes from './DataBaseRoutes/DataBaseProcessingRoutes';
import DataBaseAccountingRoutes from './DataBaseRoutes/DataBaseAccountingRoutes';
import DataBaseDataHandlingRoutes from './DataBaseRoutes/DataBaseDataHandlingRoutes';
import DataBaseAdminRoutes from './DataBaseRoutes/DataBaseAdminRoutes';

import ClientPortalCasesRoutes from './ClientPortalRoutes/ClientPortalCasesRoutes';
import ClientPortalAdminRoutes from './ClientPortalRoutes/ClientPortalAdminRoutes';
import ClientPortalRequestsRoutes from './ClientPortalRoutes/ClientPortalRequestsRoutes';
import DataBaseScheduleRoutes from './DataBaseRoutes/DataBaseScheduleRoutes/DataBaseScheduleRoutes';
import DataBaseReportsRoutes from './DataBaseRoutes/DataBaseReportsRoutes';
import ClientPortalDashboard from '../components/pages/ClientPortalDashboard';
import DataBaseDashboardRoutes from './DataBaseRoutes/DataBaseDashboardRoutes';

import ClientPortalInvoices from '../components/pages/ClientPortalInvoices/ClientPortalInvoices';
import { rolesCP, rolesDB } from '../utils/roleHelpers';
import SelfEnrollmentLanding from '../components/pages/SelfEnrollment/SelfEnrollmentLanding';
import SelfEnrollmentForms from '../components/pages/SelfEnrollment/SelfEnrollmentForms';
import ForgotPasswordLanding from '../components/pages/ForgotPassword/ForgotPasswordLanding';
import RequestPasswordRecovery from '../components/pages/ForgotPassword/RequestPasswordRecovery';
import ForgotPassword from '../components/pages/ForgotPassword/ForgotPassword';
import ClientPortalFeeSchedule from '../components/pages/ClientPortalFeeSchedule';
import ClientPortalLegalForms from '../components/pages/ClientPortalLegalForms';
import ViewLegalFormHistory from '../components/pages/ClientPortalLegalForms/ViewLegalFormHistory';

const { Admin, Attorney, SchedulingAssistant, LegalAssistant, Accounting } = rolesDB;
const { ClientPortalAdmin } = rolesCP;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RoutesWrapper />}>
      <Route path="cp/*" element={<RoutesGuard isCP />}>
        <Route path="login" element={<LoginToDashboard base="cp" />} />
        <Route path="dashboard" element={<Layout component={ClientPortalDashboard} />} />
        <Route path="cases/*" element={<Layout component={ClientPortalCasesRoutes} />} />
        <Route path="requests/*" element={<Layout component={ClientPortalRequestsRoutes} />} />
        <Route
          path="admin/*"
          element={
            <RoleGuard whitelist={[Admin, ClientPortalAdmin]}>
              <Layout component={ClientPortalAdminRoutes} />
            </RoleGuard>
          }
        />
        <Route path="invoices" element={<Layout component={ClientPortalInvoices} />} />

        <Route path="legalforms" element={<Layout component={ClientPortalLegalForms} />} />
        <Route path="legalforms/history" element={<Layout component={ViewLegalFormHistory} />} />
        <Route path="feeschedule" element={<Layout component={ClientPortalFeeSchedule} />} />
        <Route path="*" element={<Layout component={NotFound} />} />
      </Route>
      <Route path="client/*" element={<RoutesGuard isCP />}>
        <Route path="login" element={<LoginToDashboard base="client" />} />
        <Route
          path="dashboard"
          element={<Layout component={ClientPortalDashboard} base="client" />}
        />
        <Route
          path="cases/*"
          element={<Layout component={ClientPortalCasesRoutes} base="client" />}
        />
        <Route
          path="requests/*"
          element={<Layout component={ClientPortalRequestsRoutes} base="client" />}
        />
        <Route
          path="admin/*"
          element={
            <RoleGuard whitelist={[Admin, ClientPortalAdmin]}>
              <Layout component={ClientPortalAdminRoutes} />
            </RoleGuard>
          }
        />
        <Route path="invoices" element={<Layout component={ClientPortalInvoices} />} />
        <Route path="legalforms" element={<Layout component={ClientPortalLegalForms} />} />
        <Route path="legalforms/history" element={<Layout component={ViewLegalFormHistory} />} />
        <Route path="feeschedule" element={<Layout component={ClientPortalFeeSchedule} />} />
        <Route path="*" element={<Layout component={NotFound} />} />
      </Route>
      <Route path="db/*" element={<RoutesGuard isDB />}>
        <Route path="login" element={<LoginToDashboard base="db" />} />
        <Route
          path="dashboard/*"
          element={
            <RoleGuard whitelist={[LegalAssistant, Attorney, Admin, SchedulingAssistant]}>
              <Layout component={DataBaseDashboardRoutes} />
            </RoleGuard>
          }
        />
        <Route path="processing/*" element={<Layout component={DataBaseProcessingRoutes} />} />
        <Route
          path="schedule/*"
          element={
            <RoleGuard whitelist={[SchedulingAssistant, Attorney, Admin]}>
              <Layout component={DataBaseScheduleRoutes} />
            </RoleGuard>
          }
        />
        <Route
          path="accounting/*"
          element={
            <RoleGuard whitelist={[Accounting, Admin]}>
              <Layout component={DataBaseAccountingRoutes} />
            </RoleGuard>
          }
        />
        <Route
          path="reports/*"
          element={
            <RoleGuard whitelist={[Attorney, Admin]}>
              <Layout component={DataBaseReportsRoutes} />
            </RoleGuard>
          }
        />
        <Route
          path="datahandling/*"
          element={
            <RoleGuard>
              <Layout component={DataBaseDataHandlingRoutes} />
            </RoleGuard>
          }
        />
        <Route
          path="admin/*"
          element={
            <RoleGuard whitelist={[Admin]}>
              <Layout component={DataBaseAdminRoutes} />
            </RoleGuard>
          }
        />
        <Route path="*" element={<Layout component={NotFound} />} />
      </Route>
      <Route element={<RoutesGuard />}>
        <Route path="" element={<ToLoginPageRedirection />} />
        <Route path="/db/login" element={<Login />} />
        <Route path="/cp/login" element={<Login />} />
        <Route path="/client/login" element={<Login clientOnly />} />
        <Route path="/:portal/request-recovery" element={<RequestPasswordRecovery />} />
        <Route path="/:portal/password-recovery/:token" element={<ForgotPasswordLanding />} />
        <Route path="/:portal/password-recovery" element={<ForgotPassword />} />
        <Route path="/self-enrollment/create" element={<SelfEnrollmentForms />} />
        <Route path="/self-enrollment/:token" element={<SelfEnrollmentLanding />} />
        <Route path="*" element={<ToLoginPageRedirection />} />
      </Route>
    </Route>,
  ),
);

export default router;
