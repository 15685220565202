/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '../../lib/apiClient';

export const getCaseStatusComparisonReport = createAsyncThunk(
  'reports/getCaseStatusComparisonReport',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/caseComparisonByStatus/${id}`);

    return data;
  },
);

export const getProductivityByAttorneyPerformanceReport = createAsyncThunk(
  'reports/getProductivityByAttorneyPerformanceReport',
  async (id) => {
    const { data } = await apiClient.get(
      `/api/reports/productivityByAttorneyPerformanceReport/${id}`,
    );
    return data;
  },
);

export const getClientBillingReport = createAsyncThunk(
  'reports/getClientBillingReport',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/clientBilling/${id}`);

    return data;
  },
);

export const createCaseStatusComparisonReport = createAsyncThunk(
  'reports/createCaseStatusComparisonReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/caseComparisonByStatus`, params);

    return data;
  },
);

export const createProductivityByAttorneyPerformanceReport = createAsyncThunk(
  'reports/createProductivityByAttorneyPerformanceReport',
  async (params = {}) => {
    const { data } = await apiClient.post(
      `/api/reports/productivityByAttorneyPerformanceReport`,
      params,
    );

    return data;
  },
);

export const createClientBillingReport = createAsyncThunk(
  'reports/createClientBillingReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/clientBilling`, params);

    return data;
  },
);

export const getCaseComparisonByEmployeeReport = createAsyncThunk(
  'reports/getCaseComparisonByEmployeeReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/caseComparisonByEmployee/${id}`);

    return data;
  },
);

export const createCaseComparisonByEmployeeReport = createAsyncThunk(
  'reports/createCaseComparisonByEmployeeReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/caseComparisonByEmployee`, params);

    return data;
  },
);

export const getCourtScheduleReport = createAsyncThunk(
  'reports/getCourtScheduleReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/courtSchedule/${id}`);

    return data;
  },
);

export const getInactiveClientReport = createAsyncThunk(
  'reports/getInactiveClientReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/inactiveClients/${id}`);

    return data;
  },
);

export const getEvictionFiledReport = createAsyncThunk(
  'reports/getEvictionFiledReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/evictionFiledDaily/${id}`);

    return data;
  },
);

export const getClientAcquisitionReport = createAsyncThunk(
  'reports/getClientAcquisitionReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/clientAcquisition/${id}`);

    return data;
  },
);

export const getCourtScheduleReportAsPdf = createAsyncThunk(
  'reports/getCourtScheduleReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/courtSchedule/${id}/export/pdf`);

    return data;
  },
);

export const getEvictionFiledReportAsPdf = createAsyncThunk(
  'reports/getEvictionFiledReportAsPdf ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/evictionFiledDaily/${id}/export/pdf`);

    return data;
  },
);

export const createCourtScheduleReport = createAsyncThunk(
  'reports/createCourtScheduleReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/courtSchedule`, params);

    return data;
  },
);

export const createInactiveClientReport = createAsyncThunk(
  'reports/createInactiveClientReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/inactiveClients`, params);

    return data;
  },
);

export const createEvictionFiledReport = createAsyncThunk(
  'reports/createEvictionFiledReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/evictionFiledDaily`, params);

    return data;
  },
);

export const createClientAcquisitionReport = createAsyncThunk(
  'reports/createClientAcquisitionReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/clientAcquisition`, params);

    return data;
  },
);

export const getListOfAvailableSubReportForCaseComparison = createAsyncThunk(
  'reports/getListOfAvailableSubReportForCaseComparison',
  async () => {
    // renamed action from getListOfAvailableStatusesForCaseComparison to match
    const { data } = await apiClient.get(`/api/reports/caseComparisonByEmployee/statuses`);
    return data;
  },
);

export const getListOfAvailableEmployeesForCaseComparison = createAsyncThunk(
  'reports/getListOfAvailableEmployeesForCaseComparison',
  async (stateCodes = []) => {
    const [stateOne, stateTwo] = stateCodes;

    let queryParams = '';

    if (stateOne) {
      queryParams = `?StateCodes=${stateOne}`;
    }

    if (stateTwo) {
      queryParams += `&StateCodes=${stateTwo}`;
    }

    const { data } = await apiClient.get(
      `/api/reports/caseComparisonByEmployee/employees${queryParams}`,
    );

    return data;
  },
);

export const getStates = createAsyncThunk('reports/states', async () => {
  const { data } = await apiClient.get(`/api/reports/courtSchedule/states`);
  return data;
});

export const getStatesForEvictionFiled = createAsyncThunk(
  'reports/getStatesForEvictionFiled',
  async () => {
    const { data } = await apiClient.get(`/api/reports/evictionFiledDaily/states`);
    return data;
  },
);

export const getCountiesForCourtScheduleReport = createAsyncThunk(
  'reports/getCountiesForCourtScheduleReport',
  async (stateCode) => {
    const { data } = await apiClient.get(
      `/api/reports/courtSchedule/counties?StateCode=${stateCode}`,
    );

    return data;
  },
);

export const getCountiesForEvictionFiledReport = createAsyncThunk(
  'reports/getCountiesForEvictionFiledReport',
  async (stateCode) => {
    const { data } = await apiClient.get(
      `/api/reports/evictionFiledDaily/counties?StateCode=${stateCode}`,
    );

    return data;
  },
);

export const getCourtsForCourtScheduleReport = createAsyncThunk(
  'reports/getCourtsForCourtScheduleReport',
  // eslint-disable-next-line default-param-last
  async (params = {}, thunkAPI) => {
    const {
      courtScheduleCourts: { items: prevStateItems = [] },
    } = thunkAPI.getState().reports;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/reports/courtSchedule/courts/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.courtId,
      value: item.courtId,
      label: item.courtName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevStateItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getCourtsForEvictionFiledReport = createAsyncThunk(
  'reports/getCourtsForEvictionFiledReport',
  // eslint-disable-next-line default-param-last
  async (params = {}, thunkAPI) => {
    const {
      evictionFiledCourts: { items: prevStateItems = [] },
    } = thunkAPI.getState().reports;

    const {
      data: {
        result: { items, hasNextPage },
      },
    } = await apiClient.post(`/api/reports/evictionFiledDaily/courts/filter`, params);

    const currentStateItems = items.map((item) => ({
      id: item.courtId,
      value: item.courtId,
      label: item.courtName,
    }));

    return {
      items:
        params.currentPage && params.currentPage > 1
          ? [...prevStateItems, ...currentStateItems]
          : currentStateItems,
      hasNextPage,
    };
  },
);

export const getClientActivityReport = createAsyncThunk(
  'reports/getClientActivityReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/clientsActivity/${id}`);

    return data;
  },
);

export const createClientActivityReport = createAsyncThunk(
  'reports/createClientActivityReport',
  async (params = {}) => {
    const { data } = await apiClient.post(`/api/reports/clientsActivity`, params);

    return data;
  },
);

export const getClientActivityReportAsPdf = createAsyncThunk(
  'reports/getClientActivityReport ',
  async (id) => {
    const { data } = await apiClient.get(`/api/reports/clientsActivity/${id}/export/pdf`);

    return data;
  },
);

const initialState = {
  error: null,
  subReports: [],
  employees: [],
  states: [],
  courtScheduleCounties: [],
  evictionFiledCounties: [],
  courtScheduleCourts: {
    items: [],
  },
  evictionFiledCourts: {
    items: [],
  },
  evictionFiled: {
    items: [],
  },
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    clearCourtScheduleCourts: (state) => {
      state.courtScheduleCourts = {
        items: [],
      };
    },
    clearEvictionFiled: (state) => {
      state.evictionFiled = {
        items: [],
      };
    },
  },
  extraReducers: {
    [getCaseStatusComparisonReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getClientBillingReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createCaseStatusComparisonReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getProductivityByAttorneyPerformanceReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createProductivityByAttorneyPerformanceReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createClientBillingReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCaseComparisonByEmployeeReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createCaseComparisonByEmployeeReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getCourtScheduleReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getInactiveClientReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getEvictionFiledReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getClientAcquisitionReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createCourtScheduleReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createInactiveClientReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createEvictionFiledReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [getClientActivityReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createClientActivityReport.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createClientAcquisitionReport.rejected]: (state, action) => {
      state.error = action.payload;
    },

    [getListOfAvailableSubReportForCaseComparison.fulfilled]: (state, action) => {
      state.subReports = action.payload.result.map((value) => ({
        label: value,
        value,
      }));
    },
    [getListOfAvailableEmployeesForCaseComparison.fulfilled]: (state, action) => {
      state.employees = action.payload.result.map((employee) => ({
        label: [employee.firstName, employee.lastName].filter((x) => x).join(' '),
        value: employee.userId,
      }));
    },
    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
    [getStatesForEvictionFiled.fulfilled]: (state, action) => {
      state.states = action.payload.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.code,
      }));
    },
    [getCountiesForCourtScheduleReport.fulfilled]: (state, action) => {
      state.courtScheduleCounties = action.payload.result.map((item) => ({
        id: item.id,
        value: item.name,
        label: item.name,
      }));
    },
    [getCountiesForEvictionFiledReport.fulfilled]: (state, action) => {
      state.evictionFiledCounties = action.payload.result.map((item) => ({
        id: item.id,
        value: item.name,
        label: item.name,
      }));
    },
    [getCourtsForCourtScheduleReport.fulfilled]: (state, action) => {
      state.courtScheduleCourts = action.payload;
    },
    [getCourtsForEvictionFiledReport.fulfilled]: (state, action) => {
      state.evictionFiledCourts = action.payload;
    },
  },
});

const { clearCourtScheduleCourts, clearEvictionFiled } = reportsSlice.actions;

export { clearCourtScheduleCourts, clearEvictionFiled };

export default reportsSlice.reducer;
