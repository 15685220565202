/* eslint-disable no-await-in-loop */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Container,
  styled,
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import { CourtScheduleColumns, CourtScheduleFormBoxes } from './CourtSchedule.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import {
  createCourtScheduleReport,
  getCourtScheduleReport,
  getCourtsForCourtScheduleReport,
  getCourtScheduleReportAsPdf,
  getCountiesForCourtScheduleReport,
  getStates,
  clearCourtScheduleCourts,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import Dropdown from '../../../atoms/Dropdown';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/ArrowDown.svg';
import Table from '../../../atoms/Table/Table';
import { rolesDB, useAllowed } from '../../../../utils/roleHelpers';
import { useAvailableStatesList } from '../../../../hooks/useAvailableStatesList';
import useAsyncLoadDropdown from '../../../../hooks/useAsyncLoadDropdown';
import notificationUtils from '../../../../utils/notificationUtils';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';

const StyledContainer = styled(Container)({
  '&': {
    marginTop: '32px',
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },
    '.MuiPaper-root': {
      marginBottom: '16px',
      borderRadius: '16px',
      boxShadow: '0px 3px 32px rgb(106 114 142 / 10%)',
    },
  },
});

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
    borderRadius: '24px',
  },
});

const CourtSchedule = () => {
  const isAdmin = useAllowed([rolesDB.Admin]);
  const userStates = useAvailableStatesList();

  const [isLoader, setIsLoader] = useState(false);
  const [lastReportId, setLastReportId] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [accordionOpened, setAccordionOpened] = useState(false);

  const dispatch = useDispatch();
  const { states, courtScheduleCourts, courtScheduleCounties } = useSelector(
    (state) => state.reports,
  );

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    dispatch(getStates());
  }, []);

  const formStateCode = form.watch('stateCode');
  const county = form.watch('county');

  useEffect(() => {
    if (!formStateCode) {
      return;
    }
    form.setValue('county', []);
    form.setValue('courts', []);
    setIsLoader(true);
    dispatch(getCountiesForCourtScheduleReport(formStateCode?.value)).finally(() => {
      setIsLoader(false);
    });
  }, [formStateCode]);

  useEffect(() => {
    if (!formStateCode) {
      return;
    }
    form.setValue('courts', []);
  }, [county]);

  const countyLabel = form.watch('county')?.label;

  const courtsArguments = useMemo(() => {
    return {
      countyName: countyLabel,
    };
  }, [countyLabel]);

  const { handleMenuScrollToBottom } = useAsyncLoadDropdown({
    hasNextPage: courtScheduleCourts?.hasNextPage,
    actionArguments: courtsArguments,
    getAction: ({ page, ...args }) =>
      getCourtsForCourtScheduleReport({
        currentPage: page,
        pageSize: 10,
        ...args,
      }),
    clearCallback: () => {
      dispatch(clearCourtScheduleCourts());
    },
  });

  const getOptions = (name) => {
    switch (name) {
      case 'stateCode':
        return isAdmin ? states : userStates;
      case 'county':
        return courtScheduleCounties;
      case 'courts':
        return courtScheduleCourts?.items || [];
      default:
        return [];
    }
  };

  const getDisabled = (name) => {
    switch (name) {
      case 'county':
        return !formStateCode;
      case 'courts':
        return !formStateCode || !form.watch('county');
      default:
        return false;
    }
  };

  const exportToPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsLoader(true);
      const data = await dispatch(getCourtScheduleReportAsPdf(lastReportId)).unwrap();
      openOrDownloadBinary({
        content: data.content,
        fileName: data.originalFileName,
        contentType: data.contentType,
      });
    } catch (error) {
      notificationUtils.error(error.message || 'Something went wrong');
    } finally {
      setIsLoader(false);
    }
  };

  const handleSubmit = async (values) => {
    const data = {
      fromDate: values.fromDate,
      toDate: values.toDate,
      countyName: values.county?.label,
      courtIds: values.allCourts ? [] : values.courts.map((item) => item.value),
    };

    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createCourtScheduleReport(data)).unwrap();
      setLastReportId(reportId);
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getCourtScheduleReport(reportId)).unwrap();
        if (code === 'Completed') {
          setTableData(items);
          setAccordionOpened(true);
          done = true;
        } else if (code === 'Error') {
          notificationUtils.error(message);
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
    }
    setIsLoader(false);
  };

  const toggleAccordion = () => () => {
    setAccordionOpened((prev) => !prev);
  };

  const getOnChange = useCallback(
    (onChange, name) => (valueOrEvent, isAll) => {
      switch (name) {
        case 'courts': {
          form.setValue('allCourts', isAll);
          return onChange(valueOrEvent);
        }
        default:
          return onChange(valueOrEvent);
      }
    },
    [],
  );

  return (
    <Stack direction="column" mb={6}>
      <ShadowBlock>
        {isLoader && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
            position="absolute"
            top="0"
            left="0"
            zIndex={10}
          >
            <CircularProgress />
          </Box>
        )}
        <Form form={form} onSubmit={handleSubmit}>
          {CourtScheduleFormBoxes.map((i) => (
            <Stack
              direction="row"
              alignItems="center"
              columnGap={3}
              rowGap={2}
              flexWrap="wrap"
              py={1}
            >
              {i.inputBoxes.map((item) => {
                if (item.isCheckboxSelect) {
                  return (
                    <Field
                      key={item.name}
                      isMandatory={item.isMandatory}
                      name={item.name}
                      render={({ field, onCustomChange, error }) => (
                        <CheckboxSelect
                          label={item.label}
                          value={field.value}
                          width={item.width}
                          error={error}
                          onMenuScrollToBottom={handleMenuScrollToBottom}
                          isMandatory={item.isMandatory}
                          placeholder={item.placeholder}
                          onChange={getOnChange(onCustomChange(field.onChange), field.name)}
                          options={getOptions(item.name)}
                          showSelectAll={item.showSelectAll}
                          isDisabled={getDisabled(item.name)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <DateOrTimePicker
                          name={item.name}
                          label={item.label}
                          isDate
                          error={error}
                          Svg={Calender}
                          selected={field.value}
                          isMandatory={item.isMandatory}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                          isDisabled={getDisabled(item.name)}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }

                return null;
              })}
            </Stack>
          ))}
          <Stack direction="row" mt={2} gap={2}>
            <MButton type="submit" size="medium" disabled={isLoader || !form.formState.isValid}>
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      {tableData ? (
        <StyledContainer maxWidth={false} disableGutters>
          <StyledAccordion expanded={accordionOpened} onChange={toggleAccordion()}>
            <AccordionSummary
              expandIcon={<ArrowDown />}
              sx={{
                '&': {
                  minHeight: 0,
                  padding: '32px',
                },
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: 0,
                },
              }}
            >
              <Typography variant="h5" fontWeight={600} mr={2}>
                Court Schedule Report
              </Typography>
              <MButton
                type="button"
                onClick={exportToPdf}
                size="medium"
                disabled={isLoader || !form.formState.isValid || !lastReportId}
              >
                Export to PDF
              </MButton>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 4 }}>
              {tableData?.map((data) => {
                return (
                  <Stack direction="column">
                    <Typography mb={5} variant="h4">
                      {data.countyName}
                    </Typography>
                    {data.courtDateTimes.map((cdt) => (
                      <Stack direction="column" mb={2}>
                        <Stack direction="row" gap={1} mb={1.5}>
                          <Typography variant="bodyM400" fontWeight={600}>
                            {moment(cdt.courtDateTime).format('MM/DD/YY')}
                          </Typography>
                          <Typography variant="bodyM400" fontWeight={600}>
                            {moment(cdt.courtDateTime).format('hh:mm A')}
                          </Typography>
                        </Stack>
                        {cdt.courts.map((court) => (
                          <Stack
                            direction="column"
                            p={3}
                            boxShadow="0px 3px 20px 0px rgba(69, 80, 121, 0.13)"
                          >
                            <Typography mb={1} variant="bodyL500" fontWeight={600}>
                              {court.courtName}
                            </Typography>
                            <Table columns={CourtScheduleColumns} rows={court.cases} />
                          </Stack>
                        ))}
                      </Stack>
                    ))}
                  </Stack>
                );
              })}
            </AccordionDetails>
          </StyledAccordion>
        </StyledContainer>
      ) : null}
    </Stack>
  );
};

export default CourtSchedule;
