import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Dropdown from '../../atoms/Dropdown';
import EmptyBlock from '../../molecules/EmptyBlock';
import Page from '../../organisms/Page/Page';
import { reportsConfig } from './Reports.constants';

const Reports = () => {
  const location = useLocation();
  const { navPage = reportsConfig.name, subNav = '' } = location?.state ?? {};
  const navigate = useNavigate();
  const [report, setReport] = useState(null);

  useEffect(() => {
    if (report)
      navigate(`/db/reports/${report.value}`, {
        state: {
          navPage,
          subNav: report.label,
        },
      });
  }, [report]);

  useEffect(() => {
    if (subNav && !report) {
      const option = reportsConfig.dropdownOptions.find((o) => o.label === subNav);
      if (option) setReport(option);
    }
    if (!subNav && report) setReport(null);
  }, [subNav]);

  return (
    <Page navPage={navPage} subNavigation={subNav}>
      <Stack direction="column">
        <Typography variant="h3" margin="0 32px">
          Reports
        </Typography>
        {!report ? (
          <Box
            display="flex"
            width="1140px"
            flexDirection="column"
            alignItems="center"
            mt={12}
            mb={25}
            mx="auto"
          >
            <EmptyBlock
              title={reportsConfig.emptyLabel}
              desc={reportsConfig.emptyHint}
              descFontSize={18}
            />
            <Box width={440} margin="32px 0 24px">
              <Dropdown
                options={reportsConfig.dropdownOptions}
                placeholder={reportsConfig.dropdownPlaceholder}
                label={reportsConfig.dropdownLabel}
                value={report}
                isColumn
                onChange={setReport}
              />
            </Box>
          </Box>
        ) : (
          <Stack paddingX={4}>
            <Box width={440} margin="32px 0">
              <Dropdown
                options={reportsConfig.dropdownOptions}
                placeholder={reportsConfig.dropdownPlaceholder}
                label={reportsConfig.dropdownLabel}
                value={report}
                isColumn
                onChange={setReport}
              />
            </Box>
            <Outlet />
          </Stack>
        )}
      </Stack>
    </Page>
  );
};

export default Reports;
