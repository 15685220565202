export const reportsConfig = {
  name: 'Reports',

  emptyLabel: `You don’t have any reports yet`,
  emptyHint: `Every report  you  create will appear here. 
To get started select report  below:`,

  dropdownLabel: 'Select Report',
  dropdownPlaceholder: '-',
  dropdownOptions: [
    { value: 'casestatuscomparison', label: 'Case Status Comparison' },
    { value: 'casecomparisonbyemployee', label: 'Case Comparison by Employee' },
    { value: 'productivityByAttorney', label: 'Productivity by Attorney' },
    { value: 'courtSchedule', label: 'Court Schedule' },
    { value: 'evictionFiledDaily', label: 'Eviction Filed Daily Report' },
    { value: 'inactiveClientReport', label: 'Inactive Client Report' },
    { value: 'attorneyPerformance', label: 'Attorney Performance Report' },
    { value: 'clientBillingReport', label: 'Client Billing Report' },
    { value: 'clientActivityReport', label: 'Client Activity Report' },
    { value: 'clientAcquisitionReport', label: 'Client Acquisition Report' },
  ],
};
