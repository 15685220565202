import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Reports from '../../../components/pages/Reports';
import CaseComparisonByEmployee from '../../../components/pages/Reports/CaseComparisonByEmployee';
import CaseStatusComparison from '../../../components/pages/Reports/CaseStatusComparison';
import CourtSchedule from '../../../components/pages/Reports/CourtSchedule';
import ProductivityByAttorney from '../../../components/pages/Reports/ProductivityByAttorney';
import EvictionFiled from '../../../components/pages/Reports/EvictionFiled/evictionFiledDaily';
import ClientActivity from '../../../components/pages/Reports/ClientActivity/ClientActivity';
import ClientAcquisition from '../../../components/pages/Reports/ClientAcquisition/ClientAcquisition';
import InactiveClient from '../../../components/pages/Reports/InactiveClient/InactiveClient';
import AttorneyPerformance from '../../../components/pages/Reports/AttorneyPerformance/AttorneyPerformance';
import ClientBillingReport from '../../../components/pages/Reports/ClientBillingReport/ClientBillingReport';

const DataBaseReportsRoutes = () => (
  <Routes>
    <Route element={<Reports />}>
      <Route path="casestatuscomparison" element={<CaseStatusComparison />} />
      <Route path="casecomparisonbyemployee" element={<CaseComparisonByEmployee />} />
      <Route path="productivityByAttorney" element={<ProductivityByAttorney />} />
      <Route path="courtSchedule" element={<CourtSchedule />} />
      <Route path="evictionFiledDaily" element={<EvictionFiled />} />
      <Route path="clientActivityReport" element={<ClientActivity />} />
      <Route path="clientAcquisitionReport" element={<ClientAcquisition />} />
      <Route path="inactiveClientReport" element={<InactiveClient />} />
      <Route path="attorneyPerformance" element={<AttorneyPerformance />} />
      <Route path="clientBillingReport" element={<ClientBillingReport />} />
      <Route index />
    </Route>
  </Routes>
);

export default DataBaseReportsRoutes;
